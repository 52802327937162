import Helmet from "react-helmet"
import React from "react"
import Optionalofferint from "src/components/modules/optionalofferint"


const OpData = import("./data/opofferfrench.json");

export default function OptionalDe() {
  return(
    <section>
      <Helmet>
      <style type="text/css">{`
        .footer{ position: relative;
          margin-top: 25px;
        }

        .footer a {
          padding: 0px 1px;
        }

        .optional-module--optionaloffer h1.optional-module--lead {
          font-size: 33px!important;
        }
        .footerlinks-module--br {
          position: relative!important;
          text-align: center;
          margin-top: 30px;
        }
        .optional-module--optionaloffer .optional-module--infotext {
          padding: 10px;
        }
    `}</style>
        <body class="white" />
        <title>Offre facultative! - Freshy Newtab et Recherche- freshysearch.com</title>
      </Helmet>
      <Optionalofferint data={OpData} language='fr'></Optionalofferint>
    </section>
  )
}
